:root {
  /*YOUTHFUL CITIES COLOURS*/
  --yellow: #fbd166;
  --pink: #f6d9d7;
  --green: #b8d98d;
  --blue: #253d88;
  --red: #f2695d;
  --brown: #673934;

  --font_heading: font(normal normal normal 45px/1.4em arial + black);
}

/* Fonts
   ========================================================================== */
@font-face {
  font-family: 'Gotham Narrow Black';
  src: url('../public/assets/theme_font/GothamNarrow-Black.eot');
  src: url('../public/assets/theme_font/GothamNarrow-Black.eot?#iefix')
      format('embedded-opentype'),
    url('../public/assets/theme_font/GothamNarrow-Black.woff2') format('woff2'),
    url('../public/assets/theme_font/GothamNarrow-Black.woff') format('woff'),
    url('../public/assets/theme_font/GothamNarrow-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham Narrow Medium';
  src: url('../public/assets/theme_font/GothamNarrow-Medium.eot');
  src: url('../public/assets/theme_font/GothamNarrow-Medium.eot?#iefix')
      format('embedded-opentype'),
    url('../public/assets/theme_font/GothamNarrow-Medium.woff2') format('woff2'),
    url('../public/assets/theme_font/GothamNarrow-Medium.woff') format('woff'),
    url('../public/assets/theme_font/GothamNarrow-Medium.ttf')
      format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham Narrow Light';
  src: url('../public/assets/theme_font/GothamNarrow-Light.eot');
  src: url('../public/assets/theme_font/GothamNarrow-Light.eot?#iefix')
      format('embedded-opentype'),
    url('../public/assets/theme_font/GothamNarrow-Light.woff2') format('woff2'),
    url('../public/assets/theme_font/GothamNarrow-Light.woff') format('woff'),
    url('../public/assets/theme_font/GothamNarrow-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham Narrow Book';
  src: url('../public/assets/theme_font/GothamNarrow-Book.eot');
  src: url('../public/assets/theme_font/GothamNarrow-Book.eot?#iefix')
      format('embedded-opentype'),
    url('../public/assets/theme_font/GothamNarrow-Book.woff2') format('woff2'),
    url('../public/assets/theme_font/GothamNarrow-Book.woff') format('woff'),
    url('../public/assets/theme_font/GothamNarrow-Book.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* Global Styles
   ========================================================================== */
#root {
  min-height: 100%;
  background-color: transparent;
}

html,
body {
  min-height: 100%;
  max-width: 100vw;
  margin: 0;
  padding: 0;
  /* overflow-x: hidden; */
  font-family: 'Gotham Narrow Book', sans-serif;
  z-index: -100;
  background-color: var(--green);
}

sup {
  margin-top: 0;
  padding-top: 0;
  line-height: 0;
}

section {
  height: 100%;
}

body {
  background-color: #f5f5f5;
}

h1 {
  font-family: 'Gotham Narrow Black', sans-serif;
  font-size: 81px;
  line-height: 88px;
}

h2 {
  font-family: 'Gotham Narrow Black', sans-serif;
  font-size: 54px;
  line-height: 60px;
}

h3 {
  font-family: 'Gotham Narrow Medium', sans-serif;
  font-size: 36px;
  line-height: 42px;
}

h4 {
  font-family: 'Gotham Narrow Light', sans-serif;
  font-size: 30px;
  line-height: 36px;
  text-transform: uppercase;
}

h5 {
  font-family: 'adelle', serif;
  font-size: 24px;
  line-height: 34px;
}

p {
  font-family: 'Gotham Narrow Book', sans-serif;
  font-size: 18px;
  line-height: 30px;
}

a:hover {
  text-decoration: none;
}

footer {
  background-color: #000000;
  min-width: 100%;
}

img {
  object-fit: cover;
}

button {
  font-family: 'Gotham Narrow Medium', sans-serif;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
}

.centered {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 30;
}

.smallText {
  font-size: 15px;
}

.flexWrapper {
  min-height: 100vh;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.flexGrow {
  flex-grow: 10;
}

/* Backgrounds & containers
   ========================================================================== */

.background {
  background-image: url('/public/assets/images/grid.png');
  background-size: cover;
  background-position: center bottom;
  width: 100%;
  height: 12vh;
  position: relative;
  top: 0;
  z-index: 10;
}

.backgroundAbsolute {
  background-image: url('/public/assets/images/grid.png');
  background-size: cover;
  background-position: center bottom;
  width: 100%;
  height: 12vh;
  position: absolute;
  top: 0;
  z-index: 10;
}

.photoBackgroundOverlay {
  background-color: #000000;
  opacity: 70%;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: -15;
}

.photoBackground {
  background-image: url('/public/assets/images/canada.jpg');
  background-size: cover;
  background-position: center;
  width: 100vw;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -20;
}

.photoBackgroundContainer {
  height: 100%;
}

.photoHeaderContainer {
  height: 40vh;
  width: 100%;
  position: relative;
  top: 0;
  left: 0;
  z-index: -10;
}

.photoHeaderImg {
  position: absolute;
  object-fit: cover;
  height: 100%;
  width: 100%;
  z-index: -5;
}

.photoHeaderOverlay {
  background-color: #000000;
  opacity: 60%;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 5;
}

.photoHeaderText {
  position: relative;
  z-index: 10;
}

.accordianContainer {
  margin: 27px;
}

.textUnderlay {
  border-radius: 35px;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 5px 10px;
}

.hidden {
  display: none;
}

.subsection {
  min-height: 100vh;
}

/* Buttons
   ========================================================================== */

.backButton {
  top: 5vh;
  left: 40px;
  z-index: 100;
}

.forwardButton {
  bottom: 8vh;
  right: 24px;
  z-index: 100;
}

.roundButton {
  width: 58px;
  height: 58px;
}

.textRoundButton {
  height: 58px;
}

.textSizeButtonDecrease {
  width: 40px;
  height: 40px;
  position: absolute;
  bottom: -154px;
  left: -102px;
}

.textSizeButtonIncrease {
  width: 40px;
  height: 40px;
  position: absolute;
  bottom: -154px;
  left: -48px;
}

.photoButtonContainer {
  height: 100px;
  width: 100%;
  position: relative;
  border-radius: 80px;
}

.photoButtonImg {
  position: absolute;
  object-fit: cover;
  border-radius: 80px;
  height: 100px;
  width: 100%;
}

.photoOverlay {
  background-color: #000000;
  opacity: 60%;
  width: 100%;
  height: 100px;
  position: absolute;
  border-radius: 80px;
  top: 0;
  z-index: 5;
}

.cardOverlay {
  background-color: #000000;
  opacity: 60%;
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: inherit;
  top: 0;
  z-index: 5;
}

.photoButtonText {
  position: relative;
  z-index: 10;
}

.smallPhotoButtonText {
  position: relative;
  z-index: 10;
}

/* @media only screen and (max-width: 500px) {
  .smallPhotoButtonText {
    display: none;
  }
} */

.photoOverlay:hover {
  background-color: var(--blue);
  opacity: 100%;
  cursor: pointer;
}

.photoButtonText:hover + .photoOverlay {
  background-color: var(--blue);
  opacity: 100%;
  cursor: pointer;
}

.photoButtonText:hover {
  cursor: pointer;
}

.changeLang {
  position: fixed;
  right: 40px;
  top: 5vh;
  z-index: 100;
}

.attributionLink {
  font-size: 1rem;
  color: #fff;
}

.attributionLink > a {
  color: #fff;
  text-decoration: underline;
  margin: 0 3px;
}

/* Menus
   ========================================================================== */

.socials {
  height: 10vh;
  bottom: 0;
  position: relative;
}

.langMenu {
  position: absolute;
  bottom: -100px;
  left: -100px;
}

.langMenuItem {
  width: 100%;
  height: 100%;
}

.langMenuItemDisabled {
  width: 100%;
  height: 100%;
  color: rgba(0, 0, 0, 0.4);
}

.langMenuItemWrapper:first-child {
  border-radius: inherit;
  padding: 24px;
  margin: 0;
}

.langMenuItemWrapper {
  border-radius: inherit;
  padding: 24px;
  margin-top: -12px;
}

.langMenuItemWrapper:hover {
  background-color: #f5f5f5;
  cursor: pointer;
}

.langMenuItemWrapper:hover .langMenuItem {
  color: var(--blue);
  cursor: pointer;
}

.langMenuItemWrapperDisabled:first-child {
  border-radius: inherit;
  padding: 24px;
  margin: 0;
}

.langMenuItemWrapperDisabled {
  border-radius: inherit;
  padding: 24px;
  margin-top: -12px;
}

.langMenuItemWrapperDisabled:hover {
  background-color: #fff;
}

.langMenuItemWrapperDisabled:hover .langMenuItem {
  cursor: default;
}

/* Loading
   ========================================================================== */

.loading {
  background-color: #fff;
  opacity: 70%;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
}

.loadingText {
  margin-bottom: 10px;
  z-index: 20;
  text-align: center;
}

.dots-bars-5 {
  text-align: center;
  width: 40px;
  height: 30px;
  --c: linear-gradient(currentColor 0 0);
  background: var(--c) 0 100%/8px 30px, var(--c) 50% 100%/8px 20px,
    var(--c) 100% 100%/8px 10px;
  background-repeat: no-repeat;
  position: relative;
  clip-path: inset(-100% 0);
}
.dots-bars-5:before {
  content: '';
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: currentColor;
  left: -16px;
  top: 0;
  animation: db5-1 2s linear infinite,
    db5-2 0.5s cubic-bezier(0, 200, 0.8, 200) infinite;
}

@keyframes db5-1 {
  0% {
    left: -16px;
    transform: translateY(-8px);
  }
  100% {
    left: calc(100% + 8px);
    transform: translateY(22px);
  }
}

@keyframes db5-2 {
  100% {
    top: -0.1px;
  }
}

/* Material UI overrides where I couldn't figure out a better way to do it
   ========================================================================== */

/* for overriding when label is blank*/
.notranslate {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

/* Art elements
   ========================================================================== */

.road {
  width: 100%;
  height: 50px;
  background-color: #000000;
  transform: translateY(50vh);
  -webkit-transform: translateY(50vh);
  -moz-transform: translateY(50vh);
  -ms-transform: translateY(50vh);
  -o-transform: translateY(50vh);
}

.clouds {
  position: relative;
  left: 0;
  right: 0;
}

.car {
  position: relative;
  top: 5px;
  left: 0;
  right: 0;
}

.progress-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 10px;
  background: var(--red);
  transform-origin: 0%;
}

.receipt-placeholder {
  height: 500px;
}

.sticky-container {
  position: sticky;
  top: 5vh;
  overflow: hidden;
}

.receipt-content {
  mix-blend-mode: multiply;
}

.receipt {
  /* background-color: #fff; */
  /* min-height: 500px; */
  background-clip: border-box;
  background-position: center;
  background-size: 100%;
  background-repeat: no-repeat;
  min-width: 300px;
  margin: 40px auto;
  padding: 30px 10px;
  -webkit-clip-path: polygon(
    0% 0%,
    7% 4%,
    14% 0%,
    18% 3%,
    25% 1%,
    34% 4%,
    41% 0%,
    49% 3%,
    59% 0%,
    66% 2%,
    73% 0%,
    79% 3%,
    85% 0%,
    92% 4%,
    100% 0,
    100% 0,
    100% 100%,
    95% 97%,
    89% 100%,
    83% 98%,
    77% 100%,
    71% 96%,
    63% 100%,
    58% 97%,
    51% 100%,
    45% 98%,
    36% 100%,
    32% 98%,
    25% 100%,
    20% 97%,
    15% 99%,
    11% 98%,
    8% 100%,
    5% 96%,
    0 100%
  );
  clip-path: polygon(
    0% 0%,
    7% 4%,
    14% 0%,
    18% 3%,
    25% 1%,
    34% 4%,
    41% 0%,
    49% 3%,
    59% 0%,
    66% 2%,
    73% 0%,
    79% 3%,
    85% 0%,
    92% 4%,
    100% 0,
    100% 0,
    100% 100%,
    95% 97%,
    89% 100%,
    83% 98%,
    77% 100%,
    71% 96%,
    63% 100%,
    58% 97%,
    51% 100%,
    45% 98%,
    36% 100%,
    32% 98%,
    25% 100%,
    20% 97%,
    15% 99%,
    11% 98%,
    8% 100%,
    5% 96%,
    0 100%
  );
  /* transform: translateY(-500px);
  -webkit-transform: translateY(-500px);
  -moz-transform: translateY(-500px);
  -ms-transform: translateY(-500px);
  -o-transform: translateY(-500px); */
}

.receipt-container {
  width: 100%;
  height: 350vh;

  /* background-color: #000000; */
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade {
  animation: fadeIn 5s;
}

.parallax-wrapper {
  overflow-y: auto;
  overflow-x: hidden;
  perspective: 10px;
  position: relative;
  top: 0;
  left: 0;
  height: 100%;
  width: 100vw;
  /* margin: 0 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%); */
}

.parallax-bg-wrapper {
  position: relative;
  /* display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  transform-style: preserve-3d;
  z-index: -1; */
}

.absolute {
  position: absolute;
  top: 0;
  left: 3%;
  height: 100%;
  width: 100%;
}

.relative {
  position: relative;
  width: 100%;
  height: 50vh;
}
/* 
.parallax-bg {
  transform: translateZ(-10px) scale(1);
  -webkit-transform: translateZ(-10px) scale(1);
  -moz-transform: translateZ(-10px) scale(1);
  -ms-transform: translateZ(-10px) scale(1);
  -o-transform: translateZ(-10px) scale(1);
}

.parallax-fg {
  transform: translateZ(5px) scale(1);
  -webkit-transform: translateZ(5px) scale(1);
  -moz-transform: translateZ(5px) scale(1);
  -ms-transform: translateZ(5px) scale(1);
  -o-transform: translateZ(5px) scale(1);
} */

/******************* LOADING *************************/

.lds-grid {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}
.lds-grid div {
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #fff;
  animation: lds-grid 1.2s linear infinite;
}
.lds-grid div:nth-child(1) {
  top: 8px;
  left: 8px;
  animation-delay: 0s;
}
.lds-grid div:nth-child(2) {
  top: 8px;
  left: 32px;
  animation-delay: -0.4s;
}
.lds-grid div:nth-child(3) {
  top: 8px;
  left: 56px;
  animation-delay: -0.8s;
}
.lds-grid div:nth-child(4) {
  top: 32px;
  left: 8px;
  animation-delay: -0.4s;
}
.lds-grid div:nth-child(5) {
  top: 32px;
  left: 32px;
  animation-delay: -0.8s;
}
.lds-grid div:nth-child(6) {
  top: 32px;
  left: 56px;
  animation-delay: -1.2s;
}
.lds-grid div:nth-child(7) {
  top: 56px;
  left: 8px;
  animation-delay: -0.8s;
}
.lds-grid div:nth-child(8) {
  top: 56px;
  left: 32px;
  animation-delay: -1.2s;
}
.lds-grid div:nth-child(9) {
  top: 56px;
  left: 56px;
  animation-delay: -1.6s;
}
@keyframes lds-grid {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}
